import { api } from "../Intercepter";

interface createEntreatyApiParams {
  callId: string;
}

export const createEntreatyApi = async ({
  callId,
}: createEntreatyApiParams) => {
  let response = await api.post(`/entreaties/create-by-call-id`, {
    callId: callId,
  });

  if (!response) {
    throw new Error("Error in Create Entreaty");
  }

  return response.data;
};
